<template>
	<el-dialog :title="'系泊试验进度数据导入'" :close-on-click-modal="false" :visible.sync="visible"
		width="950px">
		<el-row>
			<el-col :span="8">
				<el-upload
						class="upload-demo"
						ref="uploadImg"
						:action="action"
						:http-request="uploadImg"
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
					<el-button v-preventReClick class="btn" type="primary" size="small">点击上传</el-button>
				</el-upload>
			</el-col>
		</el-row>
		<el-row style="margin-top: 15px;">
			<el-col :span="24">
				<el-table ref="dataListTable" class="dataListTable" :data="dataForm.dataList"
						  header-align="center" height="400px" style="width: 100%;"
						  header-cell-class-name="dataListTHeader">
					<el-table-column type="index" width="60" align="center"></el-table-column>
					<el-table-column prop="testType" label="专业" width="100" align="center"></el-table-column>
					<el-table-column prop="finishDate" label="完成时间" width="120" align="center"></el-table-column>
					<el-table-column prop="testItem" label="系泊试验项目" align="left"></el-table-column>
					<el-table-column prop="remark" label="备注 " width="200" align="left"></el-table-column>
					<el-table-column label="操作" width="80" align="center">
						<template slot-scope="scope">
							<el-button v-preventReClick type="text" @click="deleteHandle(scope.$index)">删除</el-button>
						</template>
					</el-table-column>
				</el-table>
			</el-col>
		</el-row>
		<el-row class="formBtnGroup">
			<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
			<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
		</el-row>
	</el-dialog>
</template>

<script>
	import img001 from "@/assets/images/001.jpg"
	import addImg from "@/assets/images/addImg.png"
	import $common from "@/utils/common.js"
	export default {
		name: "information-add-or-update",
		data() {
			return {
				visible: true,
				action: '',
				form: {},
				dataForm: {
					workNo: '',
					subjectNo: '',
					dataList: []
				}
			};
		},
		components: {

		},
		// mounted() {
		// 	this.getSubjectDropDown();
		// },
		methods: {
			uploadImg(param) {
				const formData = new FormData();
				formData.append('file', param.file);
				this.$refs.uploadImg.clearFiles();
				this.$http.post(
						this.$store.state.httpUrl + "/business/mooringtestprogressrecord/uploadFileList",
						formData, {
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						},
				).then(res => {
					res = res.data
					if (res.resultCode === 200) {
						this.dataForm.dataList = res.body
					} else {
						this.$message.info('文件上传失败')
					}
				})
			},
			deleteHandle(idx){
				this.dataForm.dataList.splice(idx,1)
			},
			init(id) {
				this.dataForm = {
					workNo: $common.getItem("workNo"),
					subjectNo: $common.getItem("subjectNo"),
					dataList: []
				}
				this.$nextTick(() => {
					this.visible = true;
				})
			},
			dataFormSubmit() {
				let records = []
				this.dataForm.dataList.forEach((item,index)=>{
					let it = {
						workNo: $common.getItem("workNo"),
						subjectNo: item.subjectName,
						testItem: item.testItem,
						finishDate: item.finishDate,
						testTypeId: item.testType,
						remark: item.remark,
						sort: item.sort
					}
					records.push(it)
				})
				this.$http({
					url: this.$store.state.httpUrl + "/business/mooringtestprogressrecord/saveBatch",
					method: "post",
					data: this.$http.adornData({
						records: records
					})
				}).then(({data}) => {
					if (data && data.resultCode === 200) {
						this.$message({
							message: "操作成功",
							type: "success",
							duration: 1500,
							onClose: () => {
								this.visible = false;
								this.$parent.getDataList();
							}
						});
					}
				});
			},

		}
	};
</script>

<style lang="scss" scoped="scoped">
	.selItemInput.descAreaLg {
		width: 718px !important;
	}

	.addImgBox {
		width: 136px;
		height: 136px;
		display: inline-block;
		vertical-align: middle;
		margin-right: 10px;
	}

	.fondstyle {
		color: #3f649f;
		font-size: 15px;
	}
</style>
